// Partials importieren
@import 'partials/_base';
@import 'partials/_colors';
@import 'partials/_variables';


.react-joyride__tooltip button{
  outline: none !important;
}

.btn {
  text-transform: uppercase;
}

h1,h2,h3,h4,h5,h6 {
  letter-spacing: -0.6%;
}