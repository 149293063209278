.Footer a {
  font-weight: 600!important;
}

.Header a {
  font-weight: 600!important;
  text-transform: uppercase;
  color: #000000!important;

  &:global(.active) {
    color: #333F55!important;
  }
}